.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.date-picker-custom-parent {
  width: 100%;
}

.date-picker-custom-parent svg {
  line-height: 1;
  text-align: center;
  height: 65%;
}

.date-picker-custom {
  width: 100%;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0;
  outline: 0;
  line-height: 1.21428571em;
  padding: .67857143em 1em;
  font-size: 1em;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, .15);
  color: rgba(0, 0, 0, .87);
  border-radius: .28571429rem;
  box-shadow: 0 0 0 0 transparent inset;
  transition: color .1s ease, border-color .1s ease;
  height: 37.094px;
}

.uploadFile {
  cursor: pointer;
  background-color: rgb(194, 200, 207);
  padding: 10px;
  border-radius: 5px;
  width: 100px;
  background-color: #1b1c1d;
  color: #fff;
  text-shadow: none;
  background-image: none;
  font-size: 1rem;
}

.ui.container.container-custom {
  margin-top: 7em;
}

@media only screen and (max-width: 767px) {
  .ui.container.container-custom {
      width: auto !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .ui.container.container-custom {
      width: auto !important;
      margin-left: 1em !important;
      margin-right: 1em !important;
  }
}

@media only screen and (min-width: 1250px) {
  .ui.container.container-custom {
      width: auto !important;
      max-width: 1250px !important;
  }
}
